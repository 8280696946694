@charset "utf-8";
/* CSS Document */

/* 基本設定-------------------------------------------*/
@import "import.scss";

a {
  @include linkBase;
  outline:none;
  position: relative;
  display: inline-block;
}
a:hover {
  @include hoverBase;
}
a img,
a svg {
  @include linkBase;
  outline:none;
}

div {
  outline:none;
  word-break: break-word;
}

img {
  max-width: 100%;
  height: auto;
  outline:none;
}

svg {
  backface-visibility: hidden;
  transform: translateZ(0);
}

div,li,header,footer,dt,dd,section {
  box-sizing: border-box;
}

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type='checkbox'],
input[type='radio'] {
  display: none;
}

input[type='submit'],
input[type='button'],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
    display: none;
}

.pc-view {
  display: none !important;
}
.sp-view {
  display: block !important;
}

@media screen and (min-width:769px){
  .pc-view {
    display: block !important;
  }
  .sp-view {
    display: none !important;
  }
}

/*-- Base --*/
html {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: -webkit-fill-available;
}
body {
  color: $basefontColor;
  background: #FFF;
  line-height: 1.7;
  @include baseFont;
  font-size: 14px;
  width: 100%;
  word-wrap: break-word;
  letter-spacing: 0.02em;
  font-feature-settings: "palt";
  min-height: -webkit-fill-available;
}
@media screen and (min-width:769px){
  body {
  }
  a[href*="tel:"] {
    // pointer-events: none;
    // cursor: default;
    text-decoration: none;
  }
}

/*-- HTML5 elements --*/
article, aside, details, figcaption, figure, footer, header, nav, section, summary {
  display: block;
  margin: 0;
  padding: 0;
}
.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.clearfix {
  min-height: 1px;
}

* html .clearfix {
  height: 1px;
  /*\*//*/
  height: auto;
  overflow: hidden;
  /**/
}

strong {
  font-weight: bold;
}

section,article {
  position: relative;
}

::selection {
}

::-moz-selection {
}

.nolink {
  pointer-events: none;
  &:hover {
    opacity: 1;
  }
}


/* 細かいパーツ-------------------------------------------*/
#page {
  position: relative;
  padding-top: 64px;
  overflow: hidden;
}
@media screen and (min-width:769px){
  #page {
  }
}


.flex-wrapper {
  display: flex;
}

.box-inner {
  margin: 0 24px;
  position: relative;
}

@media screen and (min-width:769px){
  .box-inner {
    margin: 0 auto;
    max-width: initial;
    padding: 0 7vw;
  }
  .pc-flex {
    display: flex;
  }
}

//英語フォント
.font-en {
  @include fontLatin;
}

.icon-svg {
  position: absolute;
  width: 0;
  height: 0;
}

//見出し
.mds-txt {
  font-size: 24px;
  margin-bottom: 38px;
  padding-top: 16px;
  position: relative;
  font-weight: bold;
  color: #4d5664;
  @media screen and (min-width:769px){
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 4px;
    background: #d9e0eb;
  }
}

// ボタン全般
.btn {
  width: fit-content;
  a {
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  img {
    vertical-align: middle;
  }
}
@media screen and (min-width:769px){
}
//more
.btn-more {
  a {
    font-weight: bold;
    color: #1e4e90;
    &:hover {
      opacity: 1;
      @media screen and (min-width:769px){
        color: #306ec4;
      }
    }
  }
}


//矢印アイコン1
.icon-arrow01 {
  transition: all 0.3s ease;
  position: relative;
  margin-left: 8px;
  .parts01 {
    stroke: #1e4e90;
    transition: all 0.3s ease;
  }
}
@media screen and (min-width:769px){
  .icon-arrow01 {
  }
  a:hover {
    .icon-arrow01 {
      svg {
        transform: translateX(10px);
      }
      .parts01 {
        stroke: #306EC4;
      }
    }
  }
}

//矢印アイコン2
.icon-arrow02 {
  position: relative;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: all 0.3s ease;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 5px;
    height: 5px;
    border-top: 2px solid #858C98;
    border-right: 2px solid #858C98;
    transform: rotate(135deg);
    transition: all 0.3s ease;
  }
}
@media screen and (min-width:769px){
  .icon-arrow02 {
  }
}

.noinfo {
  color: #888;
  font-size: 16px;
}
@media screen and (min-width: 769px) {
  .noinfo {
    font-size: 20px;
  }
}



/* アニメーション-------------------------------------------*/
.fade-element {
  opacity: 0;
  transform: translateY(20px);
  transition: all .5s;
}

.fadein {
  opacity: 1 !important;
  transform: translateY(0);
}


/* 共通ヘッダー-------------------------------------------*/
.page-hd {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  padding: 9px 16px 0 15px;
  z-index: 10;
  background: #fff;
  transition: .3s;
  @media screen and (min-width:769px){
    padding: 0;
	}
	.hd-inner {
    display: flex;
    align-items: center;
    @media screen and (min-width:769px){
      height: 100%;
    }
		h1 {
      margin-right: auto;
      padding-top: 5px;
      transition: .3s;
      @media screen and (min-width:769px){
        flex: 1;
        padding-right: 10px;
        margin-left: 10px;
        padding-top: 0;
      }
      img {
        width: 46vw;
        max-width: 213px;
        @media screen and (min-width:769px){
          width: 213px;
        }
      }
		}
    .pc-nav {
      display: none;
      @media screen and (min-width:769px){
        display: flex;
      }
      .nav-txt {
        margin-right: 32px;
        a {
          color: #1e4e90;
          font-weight: bold;
          position: relative;
          @media screen and (min-width:769px){
            &::before {
              content: "";
              width: 0;
              height: 1px;
              background: #1e4e90;
              position: absolute;
              bottom: -20px;
              left: 0;
              transition: .3s;
            }
            &:hover {
              &::before {
                width: 100%;
              }
            }
          }
        }
        &.trigger {
          .icon-arrow02 {
            margin-left: 10px;
            &::before {
              top: -4px;
            }
          }
        }
      }
      .keyword-btn {
        padding-left: 32px;
        border-left: 1px solid #BABABA;
        margin-right: 32px;
        img {
          vertical-align: middle;
          width: 16px;
          margin-right: 16px;
        }
        .icon-arrow02 {
          margin-left: 15px;
        }
        a {
          &:hover {
            opacity: 1;
          }
          @media screen and (min-width:769px){
            &::before {
              content: "";
              width: 0;
              height: 1px;
              background: #1e4e90;
              position: absolute;
              bottom: -20px;
              left: -30px;
              transition: .3s;
            }
            &:hover {
              &::before {
                width: 122%;
              }
            }
          }
        }
      }
    }
		.btn-menu {
      width: 32px;
      height: 41px;
      text-align: center;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
      @media screen and (min-width:769px){
        display: none;
      }
      p {
        z-index: 1;
        position: relative;
      }
      .hbg-wrapper {
        position: relative;
        width: 32px;
        height: 14px;
        margin: 0 auto;
      }
      span {
        position: absolute;
        right: 0;
        left: 0;
        margin: 0 auto;
        height: 2px;
        width: 100%;
        background-color: #1E4E90;
        display: inline-block;
        box-sizing: border-box;
        transition: all 0.3s ease;
        z-index: 1;
        &:nth-of-type(1) {
          top: 0;
        }
        &:nth-of-type(2) {
          top: 6px;
        }
        &:nth-of-type(3) {
          bottom: 0;
        }
      }
      &.open {
        span {
          &:nth-child(1) {
            transform: translateY(5px) rotate(-45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: translateY(-7px) rotate(45deg);
          }
        }
      }
    }
    .btn-order {
      width: 11.6vw;
      height: 100%;
      display: none;
      @media screen and (min-width:769px){
        display: block;
      }
      a {
        display: none;
        justify-content: center;
        align-items: center;
        color: #1e4e90;
        background: #D8E0EB;
        text-align: center;
        padding: 9px 0;
        font-size: 11px;
        height: 100%;
        box-sizing: border-box;
        @media screen and (min-width:769px){
          display: flex;
          flex-direction: column;
          font-size: 16px;
          font-weight: bold;
          span {
            font-size: 12px;
          }
        }
      }
    }
    .btn-contact {
      width: 20vw;
      height: 100%;
      @media screen and (min-width:769px){
        width: 14vw;
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: #1e4e90;
        text-align: center;
        padding: 9px 0;
        font-size: 11px;
        height: 100%;
        box-sizing: border-box;
        @media screen and (min-width:769px){
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
	}
}

/* グローバルナビ-------------------------------------------*/
body,html {
  &.fixed {
    overflow: hidden;
  }
}
.gnav {
  display: none;
  z-index: 10;
  position: relative;
  @media screen and (min-width:769px){
	}
  .gnav-inner {
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: scroll;
    background: #fff;
    padding: 10.5vw;
    padding-bottom: 22vw;
  }
  .mds-txt {
    font-size: 16px;
    margin-bottom: 18px;
    &::before {
      height: 2px;
    }
  }
  .keyword-list {
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 4px;
      margin-bottom: 4px;
      a {
        font-size: 18px;
        color: #1e4e90;
        padding: 6px 10px;
        background: #fff;
        display: block;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .menu-list {
    li {
      margin-bottom: 27px;
      a {
        font-weight: bold;
        color: #1e4e90;
        font-size: 16px;
        &:hover {
          opacity: 1;
          text-decoration: underline;
        }
      }
    }
  }
}
#gnav02 {
  .gnav-inner {
    height: 96px;
    padding: 40px 0;
    .menu-list {
      display: flex;
      justify-content: flex-end;
      width: fit-content;
      li {
        margin-left: 32px;
        a {
          &::before {
            content: none;
          }
          &:hover {
          }
        }
      }
    }
  }
}
#gnav03 {
  .gnav-inner {
    width: 40%;
    padding: 40px 30px;
    height: 200px;
    left: auto;
    right: 0;
    .keyword-list {
      margin-bottom: 0;
      li {
        a {
          &::before {
            content: none;
          }
        }
      }
    }
  }
}
#gnav02 .gnav-inner,
#gnav03 .gnav-inner {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#gnav02 .gnav-inner::-webkit-scrollbar,
#gnav03 .gnav-inner::-webkit-scrollbar {
  display:none;
}

/* パンくず-------------------------------------------*/
.breadcrumb-area {
  background: #F7F9FC;
  display: block;
  margin: 0 auto;
  padding: 18px 15px;
  width: 100%;
  @media screen and (min-width:769px){
    padding: 23px 0;
  }
  .breadcrumb {
    font-size: 12px;
    @media screen and (min-width:769px){
      margin: 0 15px;
    }
    li {
      position: relative;
      padding-right: 22px;
      display: inline;
      &:after {
        position: absolute;
        content: ">";
        vertical-align: middle;
        left: auto;
        right: 7px;
        bottom: 0;
        top: -4px;
        margin: auto;
        text-decoration: none;
      }
      a {
        color: #1e4e90;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
      &.current {
        a {
          color: #292F38;
        }
      }
    }
  }
}

/* コンテンツエリア-------------------------------------------*/


/* 下層タイトル-------------------------------------------*/
.ttl-area {
  color: #4d5664;
  background: #f7f9fc;
  text-align: center;
  padding: 41px 10px;
  @media screen and (min-width:769px){
    padding: 26px 10px 83px;
    font-size: 40px;
    letter-spacing: 0.1em;
  }
  .submds {
    font-size: 18px;
    line-height: 1.5;
    @media screen and (min-width:769px){
    }
  }
  .ttl-mds {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.4;
    @media screen and (min-width:769px){
      font-size: 40px;
      letter-spacing: 0.1em;
    }
  }
}


/* お知らせ共通パーツ-------------------------------------------*/
.info-list {
  li {
    margin-bottom: 16px;
    @media screen and (min-width:769px){
      margin-bottom: 37px;
    }
    a {
      @media screen and (min-width:769px){
        display: flex;
      }
      .date {
        @media screen and (min-width:769px){
          width: 130px;
        }
      }
      .ttl {
        font-size: 16px;
        font-weight: bold;
        color: #1e4e90;
        @media screen and (min-width:769px){
          flex: 1;
        }
      }
    }
  }
}

/* サービス内容共通パーツ-------------------------------------------*/
.service-list-wrapper {
  .mds {
    color: #1e4e90;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    .icon-arrow01 {
      margin-left: 16px;
      &::before {
        width: 56px;
        height: 8px;
        background-image: url(../images/common/icon_arrow01_l.svg);
      }
    }
  }
  .detail {
    font-size: 12px;
  }
  .service-box-l {
    padding: 51px 24px 14px;
    background-repeat: no-repeat;
    //background-image: url(../images/common/bg_service01.jpg);
    background-size: auto 100%;
    background-position: 80% 80%;
    @media screen and (min-width:769px){
      background-size: cover;
      background-position: center;
      padding: 4vw 3.5vw 3vw;
    }
    .mds {
      .icon-arrow01 {
      }
    }
    .detail {
      margin-bottom: 30px;
    }
    .text-list {
      display: flex;
      flex-wrap: wrap;
      padding: 16px 0;
      @media screen and (min-width:769px){
        max-width: 717px;
      }
      li {
        background: rgba(255, 255, 255, 0.9);
        margin-right: 4px;
        margin-bottom: 4px;
        width: calc(100% / 2 - 2px);
        @media screen and (min-width:769px){
          min-width: 153px;
          width: auto;
          margin-right: 8px;
          margin-bottom: 8px;
          font-size: 16px;
          text-align: center;
        }
        &:nth-child(2n) {
          margin-right: 0;
          @media screen and (min-width:769px){
            margin-right: 8px;
          }
        }
        a {
          font-weight: bold;
          color: #1e4e90;
          padding: 12px 16px 20px;
          box-sizing: border-box;
          display: block;
          height: 100%;
          &:hover {
            opacity: 1;
          }
          @media screen and (min-width:769px){
            padding: 25px 5.1vw 25px 2vw;
            &:hover {
              color: #306ec4;
            }
          }
          .icon-arrow01 {
            display: block;
            margin-left: 0;
            margin-top: 10px;
            @media screen and (min-width:769px){
              display: inline;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .service-box {
    a {
      width: 100%;
    }
    @media screen and (min-width:769px){
      display: flex;
      flex-wrap: wrap;
      a {
        width: 50%;
        overflow: hidden;
      }
    }
      .service-box-s {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 16px 0;
        transition: .3s;
        overflow: hidden;
        @media screen and (min-width:769px){
          width: 100%;
          height: 100%;
          padding: 16px;
          display: flex;
          align-items: center;
        }
      .mds {
        .icon-arrow01 {
        }
      }
      .detail {
        background: linear-gradient(to right,#fff 0%, rgba(255, 255, 255, 0) 100%);
        padding: 30px;
        padding-right: 30vw;
        transition: .3s;
        @media screen and (min-width:769px){
          padding: 30px 20px;
          padding-right: 50%;
        }
      }
    }
    .box02 {
      //background-image: url(../images/common/bg_service02.jpg);
      .mds {
        .icon-arrow01 {
        }
      }
      .detail {
      }
    }
    @media screen and (min-width:769px){
      a:hover {
        opacity: 1;
        .service-box-s {
          transform: scale(1.1);
          .detail {
            transform: scale(0.91);
          }
        }
        .mds {
          color: #306ec4;
        }
      }
    }
    .box03 {
     // background-image: url(../images/common/bg_service03.jpg);
      .mds {
        .icon-arrow01 {
        }
      }
      .detail {
      }
    }
    .box04 {
      //background-image: url(../images/common/bg_service04.jpg);
      .mds {
        .icon-arrow01 {
        }
      }
      .detail {
      }
    }
    .box05 {
      //background-image: url(../images/common/bg_service05.jpg);
      .mds {
        .icon-arrow01 {
        }
      }
      .detail {
      }
    }
  }
}

/* ブログ共通パーツ-------------------------------------------*/
.article-list {
  @media screen and (min-width:769px){
    display: flex;
    flex-wrap: wrap;
  }
  li {
    margin-bottom: 42px;
    @media screen and (min-width:769px){
      width: calc(100% / 3 - 20px);
      margin-right: 30px;
      margin-bottom: 30px;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    a {
      .img-box {
        position: relative;
        margin-bottom: 12px;
        overflow: hidden;
        &:hover {
          opacity: 1;
          @media screen and (min-width:769px){
            .mds {
              color: #306ec4;
            }
          }
        }
        img {
          transition: .3s;
        }
        .category {
          position: absolute;
          bottom: 0;
          left: 0;
          color: #fff;
          background: rgba(77,86,100,0.7);
          padding: 3px 6px;
          text-align: center;
        }
        .icon-new {
          position: absolute;
          top: 0;
          left: 0;
          color: #1e4e90;
          background: #fff;
          padding: 3px 6px;
          text-align: center;
        }
      }
      .txt-box {
        transition: .3s;
        .mds {
          color: #1e4e90;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 10px;
          line-height: 1.5;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          transform: .3s;
        }
        .date {
        }
        .tag {
          color: #858c98;
          font-size: 11px;
          span {
            margin-right: 2px;
          }
        }
      }
      &:hover {
        opacity: 1;
        .img-box {
          img {
            transform: scale(1.1);
          }
        }
        @media screen and (min-width:769px){
          .mds {
            color: #306ec4;
          }
        }
      }
    }
  }
  &.type02 {
    li {
      a {
        &:hover {
          opacity: 1;
          @media screen and (min-width:769px){
            .mds {
              color: #306ec4;
            }
          }
        }
        .txt-box {
          transition: .3s;
          backdrop-filter: blur(30px);
          -webkit-backdrop-filter: blur(30px);
          background-color: rgba(255, 255, 255, 0.15);
          margin-top: -18px;
          padding: 16px;
          width: 90%;
          .mds {
            -webkit-line-clamp: 3;
          }
        }
      }
    }
  }
}
.side-area {
  flex: 1;
  .mds-txt {
  }
  .side-list {
    margin-bottom: 60px;
    li {
      border-bottom: 1px solid #D9E0EB;
      &:first-child {
        border-top: 1px solid #D9E0EB;
      }
      a {
        display: block;
        color: #1e4e90;
        font-weight: bold;
        padding: 15px 0;
        @media screen and (min-width:769px){
          font-size: 16px;
        }
        .date {
          font-size: 12px;
          font-weight: normal;
          color: #2a2f37;
          display: block;
        }
      }
    }
  }
}

/* contact共通パーツ-------------------------------------------*/
.contact-box {
  background: #1e4e90;
  color: #fff;
  padding: 40px 0;
  @media screen and (min-width:769px){
    max-width: 1000px;
    margin: 0 auto;
    padding: 3.2vw 2vw 3.2vw 5vw;
  }
  .pc-flex {
    @media screen and (min-width:769px){
      justify-content: space-between;
      align-items: center;
    }
  }
  .box-l {
    .mds-txt {
      color: #fff;
      font-size: 20px;
      margin-bottom: 18px;
      &::before {
        background: #858c98;
      }
    }
    .txt01 {
      font-size: 18px;
    }
    .txt02 {
      font-size: 16px;
    }
    .txt03 {
      margin-bottom: 18px;
      font-size: 11px;
    }
  }
  .box-r {
    margin-right: 20px;
    @media screen and (min-width:769px){
      margin-right: 0;
      width: 50%;
    }
    .btn02 {
      width: 90%;
      @media screen and (min-width:769px){
        margin-left: auto;
      }
      a {
        background: #fff;
        color: #1e4e90;
        font-weight: bold;
        text-align: center;
        display: block;
        padding: 28px 0;
      }
    }
  }
}
.order-box {
  background: #D8E0EB;
  color: #1e4e90;
  @media screen and (min-width:769px){
  }
  .box-l {
    .mds-txt {
      color: #1e4e90;
      &::before {
        background: #1E4E90;
      }
    }
  }
  .box-r {
    .btn02 {
      a {
        background: #fff;
        color: #1e4e90;
      }
    }
  }
}

/* WYSIWYG-------------------------------------------*/
.blog-detail-box {
  @media screen and (min-width:769px){
  }
  .ttl {
    @media screen and (min-width:769px){
    }
  }
}
.main-txt-box {
  line-height: 1.8;
  @media screen and (min-width:769px){
    font-size: 16px;
  }
  p {
    @media screen and (min-width:769px){
    }
  }
  a {
    text-decoration: underline;
  }
  strong,b {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  img {
    max-width: 100%;
    //margin: 10px auto 15px 0;
    //display: block;
    //max-height: 600px;
    width: auto;
    height: auto;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  table {
    th {
      text-align: center;
      vertical-align: inherit;
      font-weight: bold;
    }
  }
  .fwN, caption, h1, h2, h3, h4, h5, h6, td, th {
    margin: 0;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.67em;
  }
  hr {
    display: block;
  }
}

/* ページャー-------------------------------------------*/
.pager-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px auto 0;
  max-width: 200px;
  @media screen and (min-width:769px){
    margin-top: 50px;
  }
  li {
    position: relative;
    font-weight: bold;
    font-size: 16px;
    &.current {
      a {
        text-decoration: underline;
      }
    }
    a {
      color: #1E4E90;
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .icon-arrow01 {
      position: absolute;
    }
  }
  .btn-back {
    border: none;
    width: 30px;
    .icon-arrow01 {
      transform: scale(-1, 1);
      left: -25px;
    }
  }
  .btn-next {
    border: none;
    width: 30px;
    .icon-arrow01 {
      right: -10px;
    }
  }
  .disable {
    opacity: 0.3;
    pointer-events: none;
  }
}


/* フッター-------------------------------------------*/
.page-ft {
  background: #4d5664;
  color: #fff;
  padding: 56px 0 60px;
  @media screen and (min-width:769px){
    padding: 50px 0;
  }
  a {
    color: #fff;
  }
	.box-inner {
    @media screen and (min-width:769px){
      justify-content: space-between;
      max-width: 1000px;
      padding: 0;

    }
    .copyright {
      @include fontLatin;
    }
		.box-l {
			.ft-ttl {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 18px;
			}
			.ft-nav01 {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
				li {
          margin-right: 10px;
          margin-bottom: 5px;
					a {
					}
				}
			}
			.ft-address {
        font-size: 12px;
        margin-bottom: 10px;
        color: #d9e0eb;
				li {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 7px;
					.mds {
            width: 23vw;
            @media screen and (min-width:769px){
              width: 100px;
            }
					}
          .txt {
            flex: 1;
          }
          a {
            display: block;
            color: #d9e0eb;
            text-decoration: underline;
            @media screen and (min-width:769px){
              display: inline;
              text-decoration: none;
              pointer-events: none;
            }
          }
				}
			}
			.ft-nav02 {
        font-size: 11px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
				li {
          margin-right: 12px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            right: -6px;
            top: 0;
            width: 1px;
            height: 100%;
            background: #fff;
          }
          &:last-child {
            margin-right: 0;
            &::before {
              content: none;
            }
          }
					a {
					}
				}
			}
			.copyright.pc-view {
        font-size: 10px;
			}
		}
		.box-r {
      display: flex;
      flex-wrap: wrap;
      @media screen and (min-width:769px){
        align-items: center;
        justify-content: flex-end;
      }
			.logo {
        width: 33%;
        margin-right: 20px;
        @media screen and (min-width:769px){
          width: 95px;
          margin-right: 0;
          margin-left: 20px;
        }
				img {
				}
			}
			.copyright.sp-view {
        width: 100%;
        font-size: 11px;
        margin-top: 20px;
			}
		}
	}
}

/* 右下のPAGETOPボタン-------------------------------------------*/
.btn-pagetop {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9;
  display: block !important;
  opacity: 1;
  width: 40px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
